import { createSlice } from '@reduxjs/toolkit';

export const recentSlice = createSlice({
  name: 'recent',
  initialState: {
    active: 0,
    winners: [],
    winnersBig: [],
    withdraws: [],
    jackpots: [],
  },
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setRecentWinners: (state, action) => {
      // eslint-disable-next-line no-underscore-dangle
      let _winners = [...action.payload, ...state.winners];
      _winners = _winners.slice(0, 8);
      state.winners = _winners;
    },
    setRecentWinnersBig: (state, action) => {
      // eslint-disable-next-line no-underscore-dangle
      let _winners = [...action.payload, ...state.winnersBig];
      _winners = _winners.slice(0, 8);
      state.winnersBig = _winners;
    },
    setRecentWithdraws: (state, action) => {
      let withdraws = [...action.payload, ...state.withdraws];
      withdraws = withdraws.slice(0, 5);
      state.withdraws = withdraws;
    },
    setJackpots: (state, action) => {
      state.jackpots = action.payload;
    },
  },
});

export const {
  setActive,
  setRecentWinners,
  setRecentWithdraws,
  setJackpots,
  setRecentWinnersBig,
} = recentSlice.actions;

export default recentSlice.reducer;
