import { useCallback, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import { deleteCookie } from 'cookies-next';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Script from 'next/script';

import BFUIStyles from 'bf-ui/dist/styles.scss';

import English from '../../locales/en/common.json';

import CarouselStyles from '../assets/styles/carousel.scss';
import styles from '../assets/styles/reset.scss';

import withReduxStore from '../redux/withReduxStore';

import {
  getOperators,
  setCategories,
  setDeviceData,
  setProviders,
} from '../redux/global.slice';

import { getCategories_req, getProviders_req } from '../api/home.api';

import { getDeviceData, isClient } from '../helpers/detector.helper';

import { getMetasForThePage_req } from '../api/meta.api';
import TokensEnum from '../enums/tokens.enum';
import { getUser_req } from '../api/user.api';
import { setUser } from '../redux/user.slice';
import {
  advertise_net_req,
  affiliate_req,
  explore_ads_req,
  hyros_ads_req,
  nexico_ads_req,
  phx_req,
  ytz_ads_req,
  eskimi_ads_req,
  dsp_ads_req,
  ppc_req,
  one_partners_req,
  dun_ads_req,
} from '../api/campaign.api';
import ErrorBoundaryWrapper from '../components/wrappers/errorBoundary/errorBoundary.wrapper';
import UserWrapper from '../components/wrappers/user/user.wrapper';
import websiteConfig from '../configs/websiteConfig';

const MainLayout = dynamic(
  () => import('../components/layouts/main/main.layout'),
  {
    loading: () => <></>,
  }
);

const HeadScriptsWrapper = dynamic(
  () => import('../components/wrappers/headScripts/headScripts.wrapper'),
  {
    loading: () => <></>,
  }
);

const MetaWrapper = dynamic(
  () => import('../components/wrappers/meta/meta.wrapper'),
  {
    loading: () => <></>,
  }
);

const GlobalEmitterWrapper = dynamic(
  () => import('../components/wrappers/globalEmitter/globalEmitter.wrapper'),
  {
    loading: () => <></>,
  }
);

const SocketWrapper = dynamic(() => import('../socket/wrapper.socket'), {
  loading: () => <></>,
});

function MyApp({ Component, pageProps, reduxStore }) {
  const router = useRouter();
  const { locale, query } = router;

  const [shortLocale] = locale ? locale.split('-') : ['en'];

  const messages = useMemo(() => {
    switch (shortLocale) {
      case 'en':
        return English;
      default:
        return English;
    }
  }, [shortLocale]);

  // const checkForAffiliate = useCallback(async () => {
  //   if (query?.aff) {
  //     await affiliate_req(query.aff, query.clickid, query.zoneid);
  //   }
  // }, [query]);

  const checkForAds = useCallback(async () => {
    // if (query?.aff) {
    //   await affiliate_req(query.aff, query.clickid, query.zoneid);
    // }
    if (query?.phx_click_id) {
      await phx_req(query.phx_click_id);
    }
    if (query?.onep_click_id) {
      await one_partners_req(query.onep_click_id, query.onep_sub_id);
    }
    if (query?.exp_click_id) {
      await explore_ads_req(query.exp_click_id, query.exp_source_id);
    }

    if (query?.h_ad_id) {
      await hyros_ads_req(query.h_ad_id);
    }
    if (query?.adv_click_id) {
      await advertise_net_req(query.adv_click_id);
    }
    if (query.utm_source && query.utm_source === 'ppc') {
      await ppc_req(query.gclid ?? query.utm_source);
    }
    // if (query?.nex_click_id && query?.nex_zone_id {
    //   await nexico_ads_req(query.nex_click_id, query?.nex_zone_id);
    // }
    if (query?.nex_click_id && query?.nex_zone_id && query?.aff) {
      await nexico_ads_req(query?.aff, query.nex_click_id, query?.nex_zone_id);
    }
    if (query?.ytz_click_id && query?.aff) {
      await ytz_ads_req(query?.aff, query.ytz_click_id);
    }

    if (query?.esk_click_id && query?.aff) {
      await eskimi_ads_req(query?.aff, query.esk_click_id);
    }

    if (query?.dsp_click_id && query?.aff) {
      await dsp_ads_req(query?.aff, query.dsp_click_id);
    }

    if (query?.dun_click_id && query?.aff) {
      await dun_ads_req(query?.aff, query.dun_click_id);
    }
    if (
      !(query?.nex_click_id && query?.nex_zone_id) &&
      !query?.ytz_click_id &&
      !query?.esk_click_id &&
      !query?.dsp_click_id &&
      !query?.dun_click_id &&
      query?.aff
    ) {
      await affiliate_req(query.aff, query.clickid, query.zoneid);
    }
  }, [query]);

  const checkForIframe = useCallback(() => {
    if (window.location !== window.parent.location) {
      window.parent.location.reload();
    }
  }, []);

  useEffect(() => {
    checkForIframe();
  }, []);

  useEffect(() => {
    // checkForAffiliate();
    checkForAds().then();
  }, [query]);

  return (
    <Provider store={reduxStore}>
      <IntlProvider
        locale={shortLocale}
        messages={messages}
        onError={() => null}
      >
        <Script
          // strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  window.gtag = gtag;
  gtag('config', '${websiteConfig.googleTagId}');`,
          }}
        />
        <HeadScriptsWrapper />
        <MetaWrapper metas={pageProps?.metas} />
        <MainLayout>
          <ErrorBoundaryWrapper>
            <Component {...pageProps} />
            <GlobalEmitterWrapper />
            <UserWrapper />
          </ErrorBoundaryWrapper>
        </MainLayout>
        <SocketWrapper />
        <style jsx>{styles}</style>
        <style jsx>{BFUIStyles}</style>
        <style jsx>{CarouselStyles}</style>
      </IntlProvider>
    </Provider>
  );
}

MyApp.getInitialProps = async (context) => {
  const { router, ctx } = context;
  const { reduxStore, req, res } = ctx;

  const deviceData = getDeviceData(req);

  if (!isClient() && ctx?.req?.url && !ctx?.req?.url.includes('/_next/')) {
    const promises = [];

    const slotToken = req?.cookies?.[TokensEnum.SLOT_TOKEN];

    if (slotToken) {
      try {
        const userData = await getUser_req(req.cookies);

        if (userData) {
          await reduxStore.dispatch(setUser(userData.user));
        } else {
          deleteCookie(TokensEnum.SLOT_TOKEN, { req, res });
          deleteCookie(TokensEnum.SLOT_REFRESH_TOKEN, { req, res });
        }
      } catch (e) {
        deleteCookie(TokensEnum.SLOT_TOKEN, { req, res });
        deleteCookie(TokensEnum.SLOT_REFRESH_TOKEN, { req, res });
      }
    }

    promises.push(reduxStore.dispatch(setDeviceData(deviceData)));
    promises.push(
      reduxStore.dispatch(
        setCategories(await getCategories_req(req.cookies, router.locale))
      )
    );
    promises.push(reduxStore.dispatch(setProviders(await getProviders_req())));
    promises.push(
      reduxStore.dispatch(getOperators(req.cookies, router.locale))
    );

    await Promise.all(promises);
  }

  context.locale = router.locale;
  const appProps = await App.getInitialProps(context);

  if (!isClient() && ctx?.req?.url && !ctx?.req?.url.includes('/_next/')) {
    const query = router.query || {};

    let url = router.asPath;
    let val = '';

    if (query?.provider) {
      url = `/${query.category}`;
      val = query.provider;
    }

    const pageMetas = await getMetasForThePage_req(
      null,
      url,
      val,
      router.locale
    );

    if (pageMetas?.length && pageMetas.length) {
      if (appProps.pageProps) {
        appProps.pageProps.metas = pageMetas;
      } else {
        appProps.pageProps = { metas: pageMetas };
      }
    }
  }

  return { ...appProps, reduxStore };
};

export default withReduxStore(MyApp);
